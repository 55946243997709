import { useEffect, useState } from 'react';
import { CategoryPage } from '@genially/public-web-components';
import type { FC } from 'react';
import WithLayout from '../WithLayout';
import { translate } from 'core/infrastructure/translator';
interface DataProps {
  content: any;
}

const TemplateCategory: FC<DataProps> = ({ content }) => {
  const translateFromContent = (key: string) => translate(content.langcode.value, key);
  const [fullBreadcrumb, setBreadcrumb] = useState([] as any[]);
  const extraCategories: any = [];
  if (content.extra && content.extra[0]) {
    content.extra.map((cat: any) => {
      const category = {
        name: cat.category.name,
        extra: true,
        mainRelatedContents: cat.category.mainRelatedContents,
        tid: cat.category.tid,
      };
      extraCategories.push(category);
    });
  }

  useEffect(() => {
    function getCDPurl(lang: string) {
      let url = '/templates/';
      switch (lang) {
        case 'pt-br': {
          url = '/' + lang + '/modelos/';
          break;
        }
        case 'es': {
          url = '/' + lang + '/plantillas/';
          break;
        }
        case 'de': {
          url = '/' + lang + '/vorlagen/';
          break;
        }
        case 'fr': {
          url = '/' + lang + '/modeles/';
          break;
        }
        case 'it': {
          url = '/' + lang + '/templates/';
          break;
        }
        default: {
          url = '/templates/';
          break;
        }
      }
      return url;
    }
    const newBreadcrumb = [
      {
        url: getCDPurl(content.langcode.value),
        title: translateFromContent('templates.breadcrumbback'),
      },
    ].concat(content.breadcrumb);

    setBreadcrumb(newBreadcrumb);
  }, []);

  return (
    <main role="main" data-node-id={content.id} about={content.url?.path}>
      <CategoryPage
        breadcrumbItems={fullBreadcrumb}
        faqs={content.faqs ? content.faqs : null}
        banner={content.banner ? content.banner : null}
        description={content.description ? content.description.value : null}
        breadcrumb={translateFromContent('templates.breadcrumbback')}
        templates={content.templates}
        masonry={content.masonry}
        teaser={content.teaser}
        header={content.header}
        subcategories={content.custom ? content.custom.data.content.entities : null}
        extraCategories={extraCategories}
        tid={content.categories[0].category.tid}
        lang={content.langcode.value}
        parentId={
          content.categories[0].category.parent
            ? content.categories[0].category.parent[0].entity.tid
            : null
        }
      />
    </main>
  );
};

export default WithLayout(TemplateCategory);
